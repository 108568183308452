.coffee-mug {
  position: relative;
  margin: 0 auto;
  width: 4em;
  height: 3em;
  border-radius: 0.05em 0.05em 1.5em 1.5em;
  box-shadow: 0 0 0 0.3em rgba(102, 77, 3,0.5);

}

.coffee-mug:after {
  content: "";
  display: block;
  position: absolute;
  left: 100%;
  top: 0.35em;
  width: 0.75em;
  height: 1em;
  box-shadow: 0 0 0 0.3em rgba(102, 77, 3,0.5);
  margin-left: 0.6em;
  border-radius: 0 50% 50% 0;
}

.coffee-mug .coffee-container {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fff;
  border-radius: 0.05em 0.05em 1.5em 1.5em;
}

.coffee-mug .coffee {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(102, 77, 3,0.25);
  animation: coffee-load 3.3s infinite;
}

.coffee-mug .coffee:before {
  content: "";
  display: block;
  width: 200%;
  height: 1em;
  position: absolute;
  left: -50%;
  top: -0.5em;
  background: #fff;
  animation: coffee-wave 0.8s ease-in-out infinite;
  border-radius: 50%;
}

@keyframes coffee-load {
  0% {
    transform: translateY(100%);
  }

  70% {
    transform: translateY(30%);
  }

  90% {
    transform: translateY(0);
  }

  95% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
  }
}

@keyframes coffee-wave {
  0% {
    transform: rotate(2deg);
  }

  50% {
    transform: rotate(-2deg);
  }

  100% {
    transform: rotate(2deg);
  }
}
@media (min-width: 768px) {
  .coffee-mug {
    position: relative;
    left: -250px;
  }
}
